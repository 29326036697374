import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import BreakLine from "./BreakLine";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "breakline"
    }}>{`BreakLine`}</h1>
    <Props of={BreakLine} mdxType="Props" />
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <Playground __position={1} __code={'<BreakLine color=\"#405AE0\" type=\"solid\" thickness={1} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      BreakLine,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BreakLine color='#405AE0' type='solid' thickness={1} mdxType="BreakLine" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      